import React, { useEffect, useState, useRef } from 'react';

import { Typography, TextField, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { SectionTitle, useStyles as useSectionStyles, useMainContext } from 'ReusableComponents';
import CustomAvatar from 'ReusableComponents/CustomAvatar';
import ProfilePictureDialog from './ProfilePictureDialog';

import { submitSettingsToAPI } from '../../utils';
import { useAuth, useAuthFuncs } from '../../AuthContext';
import { useUpdateProfileContext } from 'ReusableComponents/ProfileContext';

const useStyles = makeStyles()((theme) => ({
  section: {
    marginTop: '8px',
    marginBottom: '40px',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-start',
  },
  avatar: {
    marginLeft: theme.spacing(1),
    width: '150px',
    height: '150px',
  },
  avatarRoot: {},
  changeAvatarButton: {
    height: '30px',
    marginTop: theme.spacing(1.5),
  },
}));

const Profile = () => {
  const { classes } = useStyles();
  const sectionClasses = useSectionStyles();
  const authUser = useAuth();
  const { fetchUserInfo } = useAuthFuncs();
  const { onOpenSnackbar } = useMainContext();
  const getProfileImage = useUpdateProfileContext();

  const [about, setAbout] = useState(authUser.about || '');
  const [pictureDialogOpen, setPictureDialogOpen] = useState(false);
  const [newPicture, setNewPicture] = useState(null);
  const [homepage, setHomepage] = useState(authUser.homepage || '');
  const [organizationName, setOrganizationName] = useState(authUser.username);

  const oldUserId = useRef(authUser?.id);
  const firstTime = useRef(true);
  useEffect(() => {
    if (authUser && (authUser.id !== oldUserId.current || firstTime.current)) {
      setAbout(authUser.about ?? '');
      setHomepage(authUser.homepage ?? '');
      oldUserId.current = authUser.id;
      firstTime.current = false;
    }
  }, [authUser]);

  const handleDialogClose = () => {
    setPictureDialogOpen(false);
    setNewPicture(null);
  };

  const handlePictureSubmit = async (picture) => {
    await submitSettingsToAPI(
      'PATCH',
      '/v3/account/profile',
      { user: authUser.id, picture },
      authUser,
      'Profile picture successfully edited.',
      onOpenSnackbar,
      fetchUserInfo,
      () => getProfileImage({ userId: authUser.id, update: true })
    );

    setPictureDialogOpen(false);
    setNewPicture(null);
  };

  const handleAboutSubmit = async (e) => {
    e.preventDefault();
    await submitSettingsToAPI(
      'PATCH',
      '/v3/account/profile',
      { user: authUser.id, about: about },
      authUser,
      'About successfully edited.',
      onOpenSnackbar,
      fetchUserInfo
    );
  };

  const handleHomepageSubmit = async (e) => {
    e.preventDefault();
    await submitSettingsToAPI(
      'PATCH',
      '/v3/account/profile',
      { user: authUser.id, homepage: homepage },
      authUser,
      'Homepage successfully edited.',
      onOpenSnackbar,
      fetchUserInfo
    );
  };

  const handleOrganizationNameSubmit = async (e) => {
    e.preventDefault();
    await submitSettingsToAPI(
      'PUT',
      '/v3/account/username',
      { username: organizationName },
      authUser,
      'Organization name successfully edited.',
      onOpenSnackbar,
      fetchUserInfo
    );
  };

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Public profile</SectionTitle.SectionTitleText>
      </SectionTitle>
      <section className={classes.section}>
        <div className={classes.avatarContainer}>
          <Typography component="h2" variant="subtitle1" className={sectionClasses.groupTitle} gutterBottom>
            Profile picture
          </Typography>
          <CustomAvatar user={authUser} />
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.changeAvatarButton}
          startIcon={<Edit />}
          onClick={() => setPictureDialogOpen(true)}
        >
          Change
        </Button>
      </section>

      {authUser?.type === 'organization' && (
        <form
          onSubmit={handleOrganizationNameSubmit}
          style={{
            width: 'min(100%, 400px + 2rem)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography component="h2" variant="subtitle1" className={sectionClasses.groupTitle} gutterBottom>
            Organization name
          </Typography>
          <TextField
            // defaultValue={authUser.homepage ? authUser.homepage : ''}
            style={{ marginRight: '1rem', marginBottom: '1rem' }}
            fullWidth
            onChange={(e) => setOrganizationName(e.target.value)}
            variant="outlined"
            className={sectionClasses.textField}
            inputProps={{ className: sectionClasses.textFieldInput }}
            value={organizationName}
          />
          <Button
            sx={{ alignSelf: 'flex-end' }}
            disabled={authUser.username === organizationName}
            variant="contained"
            className={sectionClasses.submitButton}
            color="primary"
            type="submit"
          >
            save changes
          </Button>
        </form>
      )}

      <form className={classes.section}>
        <Typography component="h2" variant="subtitle1" className={sectionClasses.groupTitle} gutterBottom>
          About
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: 'min(100%, calc(260px + 140px + 2rem))',
          }}
        >
          <TextField
            // defaultValue={authUser.about ? authUser.about : ''}
            style={{ marginRight: '1rem', marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
            multiline
            rows={4}
            onChange={(e) => setAbout(e.target.value)}
            value={about}
            variant="outlined"
            className={sectionClasses.textField}
          />
          <Button
            disabled={about === authUser.about || (authUser.about == null && about === '')}
            variant="contained"
            className={sectionClasses.submitButton}
            color="primary"
            onClick={handleAboutSubmit}
          >
            save changes
          </Button>
        </div>
      </form>
      <form
        onSubmit={handleHomepageSubmit}
        style={{
          width: 'min(100%, 400px + 2rem)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography component="h2" variant="subtitle1" className={sectionClasses.groupTitle} gutterBottom>
          Homepage
        </Typography>
        <TextField
          // defaultValue={authUser.homepage ? authUser.homepage : ''}
          style={{ marginRight: '1rem', marginBottom: '1rem' }}
          fullWidth
          onChange={(e) => setHomepage(e.target.value)}
          variant="outlined"
          className={sectionClasses.textField}
          inputProps={{ className: sectionClasses.textFieldInput }}
          value={homepage}
          error={homepage?.includes('google.com')}
          helperText={homepage?.includes('google.com') ? 'Nice try' : ''}
        />
        <Button
          sx={{ alignSelf: 'flex-end' }}
          disabled={
            homepage === authUser.homepage ||
            homepage?.includes('google.com') ||
            (authUser.homepage == null && homepage === '')
          }
          variant="contained"
          className={sectionClasses.submitButton}
          color="primary"
          type="submit"
        >
          save changes
        </Button>
      </form>

      <ProfilePictureDialog
        open={pictureDialogOpen}
        handleClose={handleDialogClose}
        setOpen={setPictureDialogOpen}
        newPicture={newPicture}
        setNewPicture={setNewPicture}
        onSubmit={handlePictureSubmit}
      />
    </div>
  );
};

export default Profile;
