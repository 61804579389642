import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import ApiManager from 'ApiManager';

import { SectionTitle, useMainContext } from 'ReusableComponents';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { useAuth, useAuthFuncs } from '../../AuthContext';

import { Add, Login } from '@mui/icons-material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import CustomAvatar from 'ReusableComponents/CustomAvatar';

const Organizations = () => {
  const history = useHistory();
  const user = useAuth();

  const [organizationName, setOrganizationName] = useState('');
  const [open, setOpen] = useState(false);
  const [openEnter, setOpenEnter] = useState(false);

  const { onOpenSnackbar } = useMainContext();
  const { fetchUserInfo } = useAuthFuncs();

  const addOrganization = useCallback(() => {
    ApiManager.post(`/v3/organization`, { username: organizationName }, user)
      .then(() => {
        fetchUserInfo();
      })
      .catch((e) => {
        console.error(e);
        onOpenSnackbar({
          content: e.message,
          level: 'error',
        });
      });

    setOpen(false);
  }, [fetchUserInfo, onOpenSnackbar, organizationName, user]);

  const handleLogin = useCallback(() => {
    ApiManager.post(`/v3/organization/logIn`, { organizationId: openEnter.id }, user)
      .then((res) => {
        fetchUserInfo({ token: res.token }, null, false, true);
        setOpen(false);
        history.push('/');
      })
      .catch((err) => {
        console.error(err);
        onOpenSnackbar({
          content: err.message,
          level: 'error',
        });
      });
  }, [fetchUserInfo, history, onOpenSnackbar, openEnter.id, user]);

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Your organizations</SectionTitle.SectionTitleText>
      </SectionTitle>
      {user.organizations?.length > 0 && (
        <List sx={{ mb: 2 }}>
          {user.organizations?.map((o) => (
            <ListItem key={o.id}>
              <ListItemAvatar>
                <CustomAvatar user={o} />
              </ListItemAvatar>
              <ListItemText primary={o.username} />
              <Tooltip title="Enter organization view">
                <Button onClick={() => setOpenEnter(o)} endIcon={<Login />} edge="end">
                  Enter
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}

      <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add an Organization
      </Button>

      <Dialog open={!!openEnter} fullWidth onClose={() => setOpen(false)}>
        <DialogTitle onClose={() => setOpenEnter(false)}>Enter organization view</DialogTitle>
        <DialogContent>{`You will enter the organization: "${openEnter.username}"`}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEnter(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleLogin} color="primary">
            Log in
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle onClose={() => setOpen(false)}>Adding a new organization</DialogTitle>
        <DialogContent>
          <TextField
            label="Organization Name"
            margin="dense"
            fullWidth
            onChange={(e) => setOrganizationName(e.target.value)}
            value={organizationName}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button disabled={!organizationName} onClick={addOrganization} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Organizations;
